import { useCallback, useEffect, useMemo, useState } from 'react'

const DOWN = 1
const UP = -1

interface Options {
  /** The initial value when the user hasn't scrolled yet. */
  initialValue: -1 | 1

  /**
   * You have to scroll this many pixels before the direction changes so that
   * rapidly scrolling up/down by 1px won't pop the menu in and out.
   */
  threshold: number
}

const DEFAULTS: Options = {
  initialValue: UP,
  threshold: 24
}

/**
 * Tells you which direction the user is scrolling in (down or up).
 *
 * @example
 *     const MyComponent = () => {
 *       const { direction } = useScrollDirection()
 *
 *       console.log(direction == UP)
 *       console.log(direction == DOWN)
 *
 *       return <div style={{ opacity: direction === DOWN ? 1 : 0 }} />
 *     }
 */

const useScrollDirection = (options: Partial<Options> = {}) => {
  // Options
  const { initialValue, threshold } = useMemo(
    () => ({ ...DEFAULTS, options }),
    [options]
  )

  const [direction, setDirection] = useState(initialValue)
  const [lastTop, setLastTop] = useState(0)

  const checkPosition = useCallback(() => {
    // Get top scroll offset
    const doc = document.documentElement
    const top =
      (window.pageYOffset || (doc && doc.scrollTop) || 0) -
      ((doc && doc.clientTop) || 0)

    // [1] "Push" the marker up or down.
    // [2] Update direction if needed.
    if (direction === DOWN) {
      if (top > lastTop) setLastTop(top)
      if (top + threshold < lastTop) setDirection(UP)
    } else {
      if (top < lastTop) setLastTop(top)
      if (top - threshold > lastTop) setDirection(DOWN)
    }
  }, [lastTop, direction])

  // Trigger the scroll handler when scrolling
  useEffect(() => {
    window.addEventListener('scroll', checkPosition)

    return () => {
      window.removeEventListener('scroll', checkPosition)
    }
  })

  return { direction }
}

/*
 * Export
 */

export default useScrollDirection
export { DOWN, UP }
