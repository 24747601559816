import debounce from 'lodash.debounce'
import React from 'react'
import Media from 'react-responsive'
import useScrollDirection, { UP } from '../../hooks/useScrollDirection'
import getActions, { Actions } from './actions'
import Area from './Area'
import NavContext from './NavContext'
import Navigation from './Navigation'
import NavigationMobile from './NavigationMobile'
import {
  AreaEntry,
  AreaList,
  NavigationProps,
  State,
  VisibleAreas
} from './types'
import { getScrollTop, getViewportHeight } from './utilities'

type AreaMetrics = Map<HTMLElement, { top: number; bottom: number }>

/**
 * Top level layout
 */

const NavLayout = (props: Props) => {
  // State
  const [state, setState] = React.useState<State>({
    navigationProps: { left: null, right: null },
    areas: {}
  })

  // Area metrics
  const [areaMetrics, setAreaMetrics] = React.useState<AreaMetrics | null>(null)

  // Actions
  const actions = React.useMemo(() => getActions(setState), [])

  // For hiding the nav on scroll
  const { direction } = useScrollDirection()

  // Resize handler
  React.useEffect(() => {
    const fn = handleResize(state.areas, setAreaMetrics)
    window.addEventListener('resize', fn)
    fn()

    return () => {
      window.removeEventListener('resize', fn)
    }
  }, [state.areas])

  // On navigation, fire off the resize handler. The page will likely resize
  // when navigating in between pages, so this should ensure that boundaries are
  // recomputed. This is particularly necessary for the Playbook.
  //
  // Also, the `typeof window` check will allow this hook to not fail when doing
  // SSR (server-side rendering).
  React.useEffect(() => {
    handleResize(state.areas, setAreaMetrics)()
  }, [
    typeof window === 'object' && window.location && window.location.pathname
  ])

  // Scroll handler
  React.useEffect(() => {
    const fn = handleScroll(state.areas, areaMetrics, actions)
    window.addEventListener('scroll', fn)
    fn()

    return () => {
      window.removeEventListener('scroll', fn)
    }
  }, [state.areas, areaMetrics])

  // This will be false on the server, or when the scroll metrics are still
  // being calculated
  const isEffectReady =
    areaMetrics && areaMetrics.size !== 0 && state.navigationProps.left

  const { navProps } = props

  return (
    <NavContext.Provider value={{ state, actions }}>
      <div>
        <Media query='(min-width: 992px)'>
          {isDesktop =>
            isDesktop || !isEffectReady ? (
              <Navigation
                {...navProps}
                {...state.navigationProps}
                fullyHidden={!isEffectReady}
                expandLinks={direction === UP}
              />
            ) : (
              <NavigationMobile external={navProps && navProps.externalLinks} />
            )
          }
        </Media>

        {props.children}
      </div>
    </NavContext.Provider>
  )
}

/**
 * Scroll handler
 */

const handleScroll = (
  areas: AreaList,
  areaMetrics: AreaMetrics | null,
  actions: Actions
) =>
  debounce(() => {
    const result = getVisibleAreas(areas, areaMetrics)
    if (!result) return

    const { top, bottom, offset } = result

    actions.setNavigationProps({
      offset,
      left: [(top && top.left) || null, (bottom && bottom.left) || null],
      right: [(top && top.right) || null, (bottom && bottom.right) || null]
    })
  }, 10 /* 1000ms / 100fps */)

/**
 * Whenever the window is resized, recompute offsets. We can do this on scroll,
 * but since .offsetTop and .offsetHeight has the potential to trigger
 * 'layout', it's better to only do them whenever its needed.
 */

const handleResize = (
  areas: AreaList,
  setAreaMetrics: (arg0: AreaMetrics) => any
) => () => {
  const map = new Map()

  const areaValues = Object.values(areas)
  areaValues.forEach((area: AreaEntry) => {
    const { ref } = area

    // If the ref isn't currently mounted(?), don't do anything;
    // This should never happen, but better be safe about it.
    if (!ref.current) return

    // Get the top and bottom offsets of the element.
    const top = ref.current.offsetTop
    const bottom = top + ref.current.offsetHeight

    map.set(ref.current, { top, bottom })
  })

  setAreaMetrics(map)
}

/**
 * Finds out what areas are visible in the current viewport.
 *
 * Checks for all the `areas` and compares them to the visible viewport (vTop/vBottom).
 *
 * @returns {Object} An object describing the `top` and `bottom` visible areas,
 * along with the offset (in pixels) of where the top ends and the bottom begins.
 *
 * @example
 *     a = getVisibleAreas(areas)
 *
 *     a.top
 *     => { ref: (React ref), left: 'dark', right: 'dark' }
 *
 *     a.bottom
 *     => { ref: (React ref), left: 'light', right: 'light' }
 *
 *     a.offset
 *     => 44
 *
 */

const getVisibleAreas = (
  areas: AreaList,
  areaMetrics: AreaMetrics | null
): VisibleAreas | undefined => {
  // If the resize handler hasn't fired yet, nothing to do
  if (!areaMetrics) return

  // Get viewport top and bottom offsets
  const vTop = getScrollTop()
  const vBottom = vTop + getViewportHeight()

  // Let's figure out which ones are on top and on the bottom.
  // These are the values that will be returend.
  let onTop: AreaEntry | null = null
  let onBottom: AreaEntry | null = null

  const areaValues = Object.values(areas)
  areaValues.forEach((area: AreaEntry, idx: number) => {
    const { ref } = area

    // If the ref isn't currently mounted(?), don't do anything;
    // This should never happen, but better be safe about it.
    if (!ref.current) return
    if (!areaMetrics) return

    // Get the top and bottom offsets of the element.
    const result = areaMetrics.get(ref.current)
    if (!result) return

    const { top: eTop, bottom: eBottom } = result

    // If element is visible, and on top
    if (!onTop && (eTop <= vTop && eBottom > vTop)) {
      onTop = area
    }
    if (!onBottom && (eTop > vTop && eTop < vBottom)) {
      // If we didn't find an area on top (perhaps it's a margin area),
      // assume it's the preceding area.
      if (!onTop && idx !== 0) onTop = areaValues[idx - 1]
      onBottom = area
    }
  })

  // @ts-ignore ...TypeScript thinks onBottom is `null`?
  const offset = onBottom ? onBottom.ref.current.offsetTop - vTop : null

  return { top: onTop, bottom: onBottom, offset }
}

interface Props {
  children: React.ReactNode
  navProps?: Partial<NavigationProps>
}

/*
 * Export
 */

NavLayout.Area = Area
export default NavLayout
export { Area }
