import React from 'react'
import { NavContextType } from './types'

/**
 * The context that's provided by NavLayout, and consumed by NavLayout.Area.
 */

const NavContext = React.createContext<NavContextType>({})

export default NavContext
