import cn from 'classnames'
import React from 'react'
import Link from './FlexibleLink'
import LinksDesktop from './LinksDesktop'
import CSS from './Navigation.module.css'
import { NavigationProps, Variant, VariantTuple } from './types'

// Images
import brandNameInvert from '../../images/logo/horizontal-sans-invert.svg'
import brandNameColor from '../../images/logo/horizontal-sans.svg'
import logoColor from '../../images/logo/monogram-color.svg'
import logoInvert from '../../images/logo/monogram-invert.svg'

/**
 * The actual navigation that gets displayed.
 * There is no magic scrolling logic here; that belongs in NavLayout.
 */

const Navigation = (props: NavigationProps) => {
  const { offset, left, right, expandLinks, fullyHidden } = props
  const { noLogotype, noMonogram } = props
  const { externalLinks } = props
  const hide = { display: 'none' }

  // Properties to be passed onto <Links />
  const linksProps = { external: externalLinks }

  return (
    <div className={cn(CSS.root, { [CSS.isHidden]: fullyHidden })}>
      <div className={CSS.leftRoot}>
        {/* Left: dark */}
        <div
          className={CSS.leftDark}
          style={getClipStyle('dark', left, offset) || hide}
        >
          {!noMonogram ? (
            <Link to='/' {...linksProps}>
              <Monogram src={logoInvert} />
            </Link>
          ) : null}

          {!noLogotype ? (
            <Link to='/' {...linksProps}>
              <img
                src={brandNameInvert}
                alt='Mashup Garage'
                className={CSS.logotype}
              />
            </Link>
          ) : null}
        </div>

        {/* Left: light */}
        <div
          className={CSS.leftLight}
          style={getClipStyle('light', left, offset) || {}}
        >
          {!noMonogram ? (
            <Link to='/' {...linksProps}>
              <Monogram src={logoColor} />
            </Link>
          ) : null}

          {!noLogotype ? (
            <Link to='/'>
              <img
                src={brandNameColor}
                alt='Mashup Garage'
                className={CSS.logotype}
              />
            </Link>
          ) : null}
        </div>
      </div>

      <div className={CSS.rightRoot}>
        {/* Right: dark */}
        <div
          className={CSS.rightDark}
          style={getClipStyle('dark', right, offset) || hide}
        >
          <LinksDesktop {...linksProps} dark expanded={expandLinks} />
        </div>

        {/* Right: light */}
        <div
          className={CSS.rightLight}
          style={getClipStyle('light', right, offset) || {}}
        >
          <LinksDesktop {...linksProps} expanded={expandLinks} />
        </div>
      </div>
    </div>
  )
}

/**
 * Returns the CSS `style` objects for each of the nav areas.
 *
 *     right =
 *     getClipStyle('light',
 */

const getClipStyle = (
  /** The variant to be displayed */
  variant: Variant,

  /** The variants that are currently visible right now [top/bottom] */
  visibleVariants: VariantTuple | null,

  /** The offset where the `top` meets the `bottom`, in pixels */
  offset: number | null | void
) => {
  // When we don't know the visible variants, just return undefined
  if (!visibleVariants) return

  const [topVariant, bottomVariant] = visibleVariants

  // Sometimes only one is visible; just show the top variant here.
  if (
    offset === null ||
    offset === undefined ||
    visibleVariants[0] === visibleVariants[1]
  ) {
    return topVariant === variant ? {} : { display: 'none' }
  }

  // 100% doesn't work very reliably
  const max = '100vw'
  const offsetPx = `${offset}px`

  // If the variant is the top variant, return it clipped to only show the top.
  // If the variant is the bottom variant, return it clipped to only show the bottom.
  switch (variant) {
    case topVariant:
      return {
        clipPath: `polygon(0 0, ${max} 0, ${max} ${offsetPx}, 0 ${offsetPx})`
      }

    case bottomVariant:
      return {
        clipPath: `polygon(0 ${offsetPx}, ${max} ${offsetPx}, ${max} ${max}, 0 ${max})`
      }
  }
}

Navigation.defaultProps = {
  expandLinks: true,
  fullyHidden: false,
  noLogotype: false
}

const Monogram = ({ src }: { src: string }) => (
  <img src={src} alt='Mashup Garage' className={CSS.monogram} />
)

/*
 * Export
 */

export default Navigation
