import React from 'react'
import CSS from './LocationSection.module.css'

const LocationSection = () => {
  return (
    <section className={CSS.root}>
      <div className={CSS.content}>
        <LocationInfoTemplate
          city='MNL'
          country='Philippines'
          address='3F Topy IV Building, 3 Economia Road, Bagumbayan, Quezon City, 1110'
          number='+63 917 3084089'
          code='+63'
        />
        <LocationInfoTemplate
          city='LDN'
          country='United Kingdom'
          address='London'
          number='+44 738 777 3405'
          code='+44'
        />
      </div>
    </section>
  )
}

/*
 * Prop types
 */

export interface TemplateProps {
  city: string
  country: string
  address: string
  number: string
  code: string
}

/**
 * Location template
 */

const LocationInfoTemplate = (props: TemplateProps) => {
  const { city, country, address, number: num, code } = props
  return (
    <div className={CSS.locationInfo}>
      <div className={CSS.info}>
        <h1 className={CSS.city}>{city}</h1>
        <div className={CSS.description}>
          <div className={CSS.country}>{country}</div>
          <div className={CSS.address}>{address}</div>
          <div className={CSS.number}>{num}</div>
        </div>
      </div>
      <div className={CSS.code}>{code}</div>
    </div>
  )
}

/*
 * Export
 */

export { LocationInfoTemplate }
export default LocationSection
