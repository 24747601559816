import { graphql, useStaticQuery } from 'gatsby'
import { LinkItem } from './LargeFooterSection'

/**
 * Returns a list of case studies as `{ path, title }` objects.
 */

const useCaseStudyList = () => {
  const query = graphql`
    query GetShortCaseStudiesList {
      allMdx(filter: { frontmatter: { template: { eq: "work" } } }) {
        edges {
          node {
            frontmatter {
              path
              shortTitle
            }
          }
        }
      }
    }
  `
  const edges: Edge[] = useStaticQuery(query).allMdx.edges

  const links: LinkItem[] = edges.map((edge: Edge) => {
    const { frontmatter } = edge.node
    const { path, shortTitle } = frontmatter
    return { path, title: shortTitle }
  })

  return links
}

interface Edge {
  node: {
    frontmatter: {
      path: string
      shortTitle: string
    }
  }
}

export default useCaseStudyList
