import React from 'react'
import FooterLinks from './FooterLinks'
import CSS from './LargeFooterSection.module.css'

const LargeFooterSection = (props: Props) => {
  return (
    <section className={CSS.root}>
      <div className={CSS.container}>
        <FooterLinks />
        <FooterCopyright />
      </div>
    </section>
  )
}

const FooterCopyright = () => {
  const year = new Date().getFullYear()
  return <div>&copy;{year} Mashup Garage</div>
}

export interface LinkItem {
  path: string
  title: string
  external?: boolean
}

interface Props {
  /** If true, links are going to be external links */
  external?: boolean
}
export default LargeFooterSection
