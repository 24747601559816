import React from 'react'
import ServicesSection from '../../components/case-studies/ServicesSection'
import Layout from '../../components/Layout'
import LocationSection from '../../components/LocationSection'
import { NavArea, NavLayout } from '../../components/Navigation'
import LargeFooterSection from '../../sections/LargeFooterSection'

const IndexPage = () => (
  <Layout>
    <NavLayout>
      <NavArea darkAndLight>
        <ServicesSection />
      </NavArea>

      <NavArea dark>
        <LocationSection />
        <LargeFooterSection />
      </NavArea>
    </NavLayout>
  </Layout>
)

export default IndexPage
