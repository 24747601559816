import './CSS'

import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import image from '../images/meta/mashup-garage.jpg'
import favicon from './../images/favicon.ico'

const Layout = ({ children }: Props) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              {
                content: data.site.siteMetadata.description,
                name: 'description'
              },
              { content: data.site.siteMetadata.keywords, name: 'keywords' },
              {
                content: data.site.siteMetadata.description,
                property: 'og:description'
              },
              {
                content: data.site.siteMetadata.description,
                name: 'twitter:description'
              },
              { content: `${image}`, property: 'og:image' },
              { content: 'summary_large_image', name: 'twitter:image' }
            ]}
            link={[{ rel: 'shortcut icon', href: `${favicon}` }]}
          >
            <html lang='en' />
          </Helmet>
          {children}
        </>
      )}
    />
  )
}

interface Props {
  children: React.ReactNode
}

export default Layout
