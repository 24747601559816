import React from 'react'
import Link from './FlexibleLink'

const mainLinks = [
  { path: '/', title: 'Home' },
  // { path: '/agile-development', title: 'How we work' },
  { path: '/case-studies', title: 'Showcase' },
  { path: '/playbook', title: 'Playbook', external: true }
]

const contactLink = {
  path: '/work-with-us',
  title: 'Contact us',
  external: false
}

/**
 * Generic list of links. Used on desktop and mobile.
 *
 * Also see `<LinksDesktop />` and `<LinksMobile />`.
 */

const Links = (props: Props) => {
  const CSS = props.classNames || {}

  return (
    <>
      {/* Main links */}
      {mainLinks.map(({ path, title, external }: LinkItem) => (
        <Link
          external={external || props.external}
          className={CSS.link}
          to={path}
        >
          {title}
        </Link>
      ))}

      {/* Contact link */}
      <Link
        external={contactLink.external || props.external}
        className={CSS.action}
        to={contactLink.path}
      >
        <span>{contactLink.title}</span>
      </Link>
    </>
  )
}

Links.defaultProps = {
  classNames: {}
}

interface LinkItem {
  path: string
  title: string
  external?: boolean
}

interface Props {
  /** If true, renders as `<a href>` (external links) */
  external?: boolean

  classNames: {
    link?: string
    action?: string
  }
}

export default Links
