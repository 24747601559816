import React from 'react'
import Link from '../../components/Navigation/FlexibleLink'
import { LinkItem } from './LargeFooterSection'
import CSS from './LinkSection.module.css'

/**
 * A section of links.
 *
 * Give either `title` or `titleLink` to have a title. If both are absent, then
 * there's no title to be rendered.
 */

const LinkSection = (props: LinkSectionProps) => {
  const { title, titleLink, links } = props

  return (
    <nav className={CSS.root}>
      {/* Title */}
      {titleLink ? (
        <h3 className={CSS.title}>
          <Link
            to={titleLink.path}
            external={titleLink.external}
            className={CSS.titleLink}
          >
            {titleLink.title}
          </Link>
        </h3>
      ) : title ? (
        <h3 className={CSS.title}>{title}</h3>
      ) : null}

      {/* Links */}
      {links.map(
        ({ path, title: linkTitle, external }: LinkItem, idx: number) => (
          <Link {...{ external }} to={path} key={idx} className={CSS.link}>
            {linkTitle}
          </Link>
        )
      )}
    </nav>
  )
}

interface LinkSectionProps {
  title?: React.ReactNode
  titleLink?: LinkItem
  links: LinkItem[]
}

export default LinkSection
