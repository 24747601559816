import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'

/**
 * A shim for gatsby-link's `<Link>`. However, if `external` is true,
 * then it will render as a regular `<a href>` instead.
 *
 * This is used since the navigation are internal links in the main website,
 * but external links in the sub-websites (eg, Playbook).
 *
 * External links are going to the domain defined in `siteMetadata.mainUrl`.
 */

const FlexibleLink = ({ to, children, external, ...linkProps }: LinkProps) => {
  const siteUrl = useMainUrl()
  const path = to.replace(/^\//, '')

  if (external) {
    return (
      <a href={`${siteUrl}${path}`} {...linkProps}>
        {children}
      </a>
    )
  } else {
    return (
      <Link to={to} {...linkProps}>
        {children}
      </Link>
    )
  }
}

export interface LinkProps {
  to: string
  children: React.ReactNode
  /** If true, renders as `<a href>`. Else renders as Gatsby `<Link />` */
  external?: boolean

  /** Passed onto `<Link>` or `<a>` */
  className?: string
}

/**
 * React hook for getting mainUrl
 */

const useMainUrl = () => {
  const QUERY = graphql`
    query MainUrlQuery {
      site {
        siteMetadata {
          mainUrl
        }
      }
    }
  `

  const meta = useStaticQuery(QUERY).site.siteMetadata
  return meta.mainUrl
}

export default FlexibleLink
