import React from 'react'
import CSS from './LargeFooterSection.module.css'
import LinkSection from './LinkSection'
import useCaseStudyList from './useCaseStudyList'

const FooterLinks = () => {
  const caseStudies = useCaseStudyList()

  return (
    <div className={CSS.links}>
      <div className={CSS.column}>
        <LinkSection
          titleLink={{ path: '/', title: 'Mashup Garage' }}
          links={[
            { path: '/', title: 'Home' },
            { path: '/agile-development', title: 'How we work' },
            { path: '/case-studies', title: 'Case studies' },
            { path: '/work-with-us', title: 'Contact us' },
            { path: '/playbook', title: 'Playbook', external: true }
          ]}
        />
      </div>

      <div className={CSS.column}>
        <LinkSection
          titleLink={{ path: '/case-studies', title: 'Case studies' }}
          links={caseStudies}
        />
      </div>

      <div className={CSS.column}>
        <LinkSection
          titleLink={{
            path: '/playbook',
            title: 'Playbook',
            external: true
          }}
          links={[
            {
              path: '/playbook/agile.html',
              title: 'Agile development',
              external: true
            },
            {
              path: '/playbook/tech.html',
              title: 'Our tech stack',
              external: true
            }
          ]}
        />

        <LinkSection
          title='Our services'
          links={[
            { path: '/elixir', title: 'Elixir & Phoenix' },
            { path: '/rails', title: 'Ruby on Rails' },
            { path: '/react', title: 'React.js' }
          ]}
        />
      </div>

      <div className={CSS.column}>
        <LinkSection
          titleLink={{ path: '/work-with-us', title: 'Contact us' }}
          links={[
            { path: '/work-with-us', title: 'Work with us' },
            { path: '/work-with-us/apply', title: 'Careers' }
          ]}
        />
      </div>
    </div>
  )
}

export default FooterLinks
