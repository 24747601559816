import Hidden from '@reach/visually-hidden'
import cn from 'classnames'
import React, { useState } from 'react'
import LinksMobile from './LinksMobile'
import CSS from './NavigationMobile.module.css'

const NavigationMobile = (props: Props) => {
  const [navShown, setNavShown] = useState(false)

  return (
    <div className={cn(CSS.root, { [CSS.isExpanded]: navShown })}>
      <button
        onClick={() => setNavShown(shown => !shown)}
        className={cn(CSS.button, { [CSS.isClose]: navShown })}
      >
        <Hidden>{navShown ? 'Hide menu' : 'Show menu'}</Hidden>
      </button>

      <div
        className={cn(CSS.menu, { [CSS.isShown]: navShown })}
        onClick={() => setNavShown(false)}
      >
        <LinksMobile external={props.external || false} />
      </div>
    </div>
  )
}

interface Props {
  external?: boolean
}

export default NavigationMobile
