import cn from 'classnames'
import React from 'react'
import Links from './Links'
import CSS from './LinksDesktop.module.css'

/**
 * Links in the top menu (desktop mode).
 */

const LinksDesktop = (props: Props) => {
  const isDark = !!props.dark
  const isExpanded = !!props.expanded

  return (
    <nav
      className={cn({
        [CSS.root]: true,
        [CSS.isDark]: isDark,
        [CSS.isCollapsed]: !isExpanded
      })}
    >
      <Links
        external={props.external}
        classNames={{
          link: CSS.link,
          action: cn(CSS.link, CSS.isAction)
        }}
      />
    </nav>
  )
}

interface Props {
  /** If true, then we're placed behind a dark background. */
  dark?: boolean

  /** Expand? */
  expanded?: boolean

  /** If true, renders as `<a href>` (external links) */
  external?: boolean
}

LinksDesktop.defaultProps = {
  dark: false,
  expanded: true
}

export default LinksDesktop
