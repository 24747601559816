import cn from 'classnames'
import React from 'react'
import logoInvert from '../../images/logo/monogram-invert.svg'
import Link from './FlexibleLink'
import Links from './Links'
import CSS from './LinksMobile.module.css'

const LinksMobile = (props: Props) => {
  return (
    <div className={CSS.root}>
      {/* Links */}
      <div className={CSS.links}>
        <Link to='/' className={CSS.brand}>
          <img src={logoInvert} alt='Mashup Garage' className={CSS.logo} />
        </Link>

        <Links
          external={props.external}
          classNames={{
            link: CSS.link,
            action: cn(CSS.link, CSS.isAction)
          }}
        />
      </div>
    </div>
  )
}

interface Props {
  /** If true, renders as `<a href>` (external links) */
  external?: boolean
}

export default LinksMobile
