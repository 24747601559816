import React from 'react'
import NavContext from './NavContext'
import { AreaProps } from './types'

/**
 * Each scrollable area.
 * All this does is register the area to the context store.
 *
 * @example
 *     <NavArea dark>...</>
 *     <NavArea light>...</>
 *     <NavArea darkAndLight>...</>
 */

const Area = (props: AreaProps) => {
  const { actions } = React.useContext(NavContext)
  if (!actions) return <>{props.children}</>

  const span = React.useRef<HTMLDivElement>(null)

  // The variants
  const { id } = props
  const [left, right] = getVariants(props)

  // Side effect: push this to the store
  React.useEffect(() => {
    return actions.registerArea({ ref: span, left, right, id })
  }, [span, left, right, id])

  return (
    <div ref={span} data-testid={`nav-layout-area:${id || '?'}`}>
      {props.children}
    </div>
  )
}

/**
 * Returns the variants for left and right
 */

const getVariants = (props: AreaProps) => {
  if (props.darkAndLight) return ['dark', 'light']
  if (props.dark) return ['dark', 'dark']
  if (props.light) return ['light', 'light']
  return [props.left, props.right]
}

export default Area
