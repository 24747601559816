import React from 'react'
import { AreaEntry, NavigationProps, SetState, State, Variant } from './types'

/* Simple unique ID generator */
let gid = 0

/**
 * Actions for the context.
 * These are accessible via `actions` in the context.
 */

const getActions = (setState: SetState) => ({
  /**
   * Sets the props that should be passed onto <Navigation />. These props
   * are determined by the scroll handler.
   */

  setNavigationProps: (newProps: NavigationProps) => {
    setState((state: State) => ({
      ...state,
      navigationProps: newProps
    }))
  },

  /**
   * Registers a scroll area so that the scroll handler will check it for
   * visibility. Triggered by the mounting of an <Area>.
   */

  registerArea: ({ ref, left, right, id }: AreaEntry) => {
    const areaId = id || (gid++).toString()
    setState((state: State) => ({
      ...state,
      areas: {
        ...state.areas,
        [areaId]: { ref, left, right }
      }
    }))

    return () => {
      setState((state: State) => {
        const { [areaId]: _, ...areas } = state.areas
        return { ...state, areas }
      })
    }
  }
})

export type Actions = ReturnType<typeof getActions>

/*
 * Export
 */

export default getActions
