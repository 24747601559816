/*
 * Get scroll top
 */

export const getScrollTop = () => {
  const doc = document.documentElement
  return (
    (window.pageYOffset || (doc && doc.scrollTop) || 0) -
    ((doc && doc.clientTop) || 0)
  )
}

/*
 * Get the height of the visible viewport
 */

export const getViewportHeight = () => {
  // Thanks: https://stackoverflow.com/a/8876069
  return Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  )
}

export const getViewportWidth = () => {
  return Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
}
