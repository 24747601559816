import { graphql, Link, useStaticQuery } from 'gatsby'
import sortBy from 'lodash.sortby'
import React from 'react'
import CSS from './ServicesSection.module.css'

/*
 * Using `sort: { order: DESC, fields: [frontmatter___weight] }` doesn't seem to work,
 * so we'll sort it on the React side.
 */

const query = graphql`
  query GetCaseStudies {
    allMdx(filter: { frontmatter: { template: { eq: "work" } } }) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            template
            title
            subheading
            description
            urlTitle
            url
            draft
            weight
          }
        }
      }
    }
  }
`

interface Frontmatter {
  date: string
  path: string
  template: string
  title: string
  subheading: string
  description: string
  urlTitle: string
  url: string
  draft: boolean | null | undefined
  weight: number | null | undefined
}

interface Edge {
  node: {
    id: string
    frontmatter: Frontmatter
  }
}

interface Result {
  allMdx: {
    edges: Edge[]
  }
}

/**
 * This is a section of `Case Studies`, not `Services`. This probably used to
 * be called Services, idk.
 */

const ServicesSection = () => {
  const data: Result = useStaticQuery(query)
  const studiesList = data.allMdx.edges
  const studies = sortBy(
    studiesList,
    ({ node }: Edge) => -(node.frontmatter.weight || 0)
  )

  return (
    <ServicesSectionTemplate
      heading='Case studies'
      description={
        <>
          <p>Here are some of our latest work.</p>
          <p>
            From forming teams to building projects, we do it.
          </p>
        </>
      }
      services={
        <>
          {studies.map((edge: Edge) => (
            <ServiceBlock
              key={edge.node.id}
              post={edge.node}
              linkTo={edge.node.frontmatter.path}
            />
          ))}
        </>
      }
    />
  )
}

export interface TemplateProps {
  heading: React.ReactNode
  description: React.ReactNode
  services: React.ReactNode
}

const ServicesSectionTemplate = (props: TemplateProps) => {
  const { heading, description, services } = props
  return (
    <section className={CSS.root}>
      <div className={CSS.container}>
        <div className={CSS.sectionInfo}>
          <h1 className={CSS.sectionHeading}>{heading}</h1>
          <div className={CSS.sectionDescription}>{description}</div>
        </div>
        <div className={CSS.services}>{services}</div>
      </div>
    </section>
  )
}

interface ServiceBlockProps {
  post: Edge['node']
  linkTo: string
}

const ServiceBlock = ({ post, linkTo }: ServiceBlockProps) => {
  const { frontmatter } = post
  const { title, subheading, description, url, urlTitle, draft } = frontmatter

  return (
    <div className={CSS.service}>
      <h2 className={CSS.serviceHeading}>
        {draft ? (
          <span>{title}</span>
        ) : (
          <Link to={linkTo} className={CSS.link}>
            {title}
          </Link>
        )}
      </h2>

      <div className={CSS.serviceSubheading}>{subheading}</div>

      <div className={CSS.serviceDescription}>{description}</div>

      <div>
        <a className={CSS.serviceLink} href={url}>
          {urlTitle}
        </a>
      </div>
    </div>
  )
}

export { ServicesSectionTemplate }
export { ServiceBlock }
export default ServicesSection
